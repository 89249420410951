import axios from './axios_interceptors'
import config from '@/services/config'

export default {
  applyCastingCall(casting_call_uuid, formdata) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/apply/`,
      formdata,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  updateApplyCastingCall(user_apply_uuid, formdata) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${user_apply_uuid}/edit-apply/`,
      formdata,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getAllPitchApplied(data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/all-pitches/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  adduserNetworksCastingCall(user_apply_uuid, formdata) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${user_apply_uuid}/add-user-network/`,
      formdata,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  responseQuestions(user_apply_uuid, answers) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${user_apply_uuid}/response/`,
      {answers},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  uploadMediaQuestions(user_apply_uuid, formdata) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${user_apply_uuid}/upload-media/`,
      formdata,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  uploadAttachments(user_apply_uuid, formdata) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${user_apply_uuid}/upload-examples/`,
      formdata,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  submitPitch(user_apply_uuid) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/${user_apply_uuid}/submit-apply/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  pitchDetail(user_apply_uuid) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/applied/${user_apply_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  deleteAttachments(user_apply_uuid, examples_uuid) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${user_apply_uuid}/delete-example/`,
      {examples_uuid},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  detailApplyPitchByUUID(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/get-pitches/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  acceptRejectPitch(pitch_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${pitch_uuid}/change-status/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  exportPitches(data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/export-pitches/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response)
      .catch(error => {
        return error.response
      });
  },
  bulkacceptRejectPitch(data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/bulk/change-status/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response)
      .catch(error => {
        return error.response
      });
  },
  rejectSubmittedPitches(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/reject-all-pitches/${casting_call_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response)
      .catch(error => {
        return error.response
      });
  }
}