import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://sandbox.core.api.brandme.la/api/v1/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar', 'Authorization': `Bearer ${localStorage.token}`}
})

Vue.prototype.$http = axiosIns

export default axiosIns
