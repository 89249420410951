import axios_module from 'axios';
import config from '@/services/config'
import useJwt from '@/auth/jwt/useJwt'
import { setuserData, setPermissions } from '@/libs/utils/user';
import abilities from '@/libs/acl/abilities'

const setUser = (data, access_token) => {
  const { refresh_token } = data;

  useJwt.setToken(access_token)
  useJwt.setRefreshToken(refresh_token)
  setPermissions(data.user);
  const read_ability = abilities.getAbilitie(data.user)
  const show_connect_message = data.user.main_group && data.user.main_group.name === 'creator'
  localStorage.setItem('show_connect_message', show_connect_message)
  setuserData(data, read_ability)
}

const axios = axios_module.create({
  baseURL: config.urlCore, 
});

axios.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  return response;
}, async error => {
  const original_request = error.config;
  if (error.response.status === 401 && !original_request._retry) {
    original_request._retry = true;
    const old_refresh_token = localStorage.getItem('refreshToken');
    if (old_refresh_token) {
      try {
        const response = await axios_module.post(`${config.urlCore}/api/v1/accounts/refresh-token/`, {
          refresh_token: old_refresh_token,
        });

        const { access_token } = response.data.response;
        if (access_token) setUser(response.data.response, access_token);
        
        return axios(original_request);
      } catch (refreshError) {
        localStorage.clear();
      }
    } else {
      localStorage.clear();
    }
  }
  return Promise.reject(error);
});

export default axios;
