const general = require('./es/general.json')
const profile = require('./es/profile.json')
const campaign = require('./es/campaign.json')
const dashboard = require('./es/dashboard.json')
const list = require('./es/list.json')
const login = require('./es/login.json')
const monitor = require('./es/monitor.json')
const register = require('./es/register.json')
const search = require('./es/search.json')
const settings = require('./es/setting.json')
const status = require('./es/status.json')
const leads = require('./es/leads.json')
const mediaKits = require('./es/mediaKits.json')
const campaignsOoh = require('./es/campaignsOoh.json')
const invitation = require('./es/invitation.json')
const proposals = require('./es/proposals.json')
const tracking = require('./es/tracking.json')
const proposals_deals_titles = require('./es/proposals_deals_title')
const brandme_ia = require('./es/brandmeia')


export default  {
  ...general,
  ...profile,
  ...monitor,
  ...campaign,
  ...dashboard,
  ...list,
  ...login,
  ...monitor,
  ...settings,
  ...register,
  ...search,
  ...status,
  ...leads,
  ...mediaKits,
  ...campaignsOoh,
  ...invitation,
  ...proposals,
  ...tracking,
  ...proposals_deals_titles,
  ...brandme_ia,
}
