export default {
  namespaced: true,
  state: {
    countries: [],
    incomplete_payment: false,
    trial_period: {trial: false},
  },
  getters: {
    allCountries: state => state.countries,
    incomplete_payment: state => state.incomplete_payment,
    trial_period: state => state.trial_period
  },
  mutations: {
    SET_COUNTRIES(state, val) {
      state.countries = val
    },
    SET_INCOMPLETE_PAYMENT(state, val) {
      state.incomplete_payment = val
    },
    SET_TRIAL_PERIOD(state, val) {
      state.trial_period = val;
    },
  },
  actions: {},
}