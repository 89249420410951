import config from '@/services/config'

export default {
  // Endpoints
  // loginEndpoint: '/jwt/login',
  loginEndpoint: `${config.urlCore}/api/v1/accounts/login/`,
  registerEndpoint: `${config.urlCore}/api/v1/accounts/register/`,
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
