export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/email-confirmation',
    name: 'email-confirm',
    component: () => import('@/views/pages/authentication/EmailConfirmation.vue'),
    meta: {
      resource: 'all',
      show_secunday_navbar: false,
      action: 'read'
    }
  }
]
