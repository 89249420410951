export default {
  brand : [
    {
      action: 'manage',
      subject: 'Brand'
    },
    {
      action: 'manage',
      subject: 'Dashboard'
    },
    {
      action: 'read',
      subject: 'Auth'
    },
    {
      action: 'read',
      subject: 'ACL'
    },
    {
      action: 'read',
      subject: 'all'
    }
  ],
  creator : [
    {
      action: 'manage',
      subject: 'Dashboard'
    },
    {
      action: 'manage',
      subject: 'Creator'
    },
    {
      action: 'read',
      subject: 'Auth'
    },
    {
      action: 'read',
      subject: 'ACL'
    },
    {
      action: 'read',
      subject: 'all'
    }
  ],
  base : [
    {
      action: 'read',
      subject: 'Auth'
    },
    {
      action: 'read',
      subject: 'ACL'
    },
    {
      action: 'read',
      subject: 'all'
    }
  ],
  super : [
    {
      action: 'manage',
      subject: 'Search'
    },
    {
      action: 'manage',
      subject: 'Lists'
    },
    {
      action: 'manage',
      subject: 'Dashboard'
    },
    {
      action: 'read',
      subject: 'Auth'
    },
    {
      action: 'read',
      subject: 'ACL'
    },
    {
      action: 'read',
      subject: 'all'
    }
  ],
  getAbilitie(user) {
    if (user.main_group !== null) {
      if (user.main_group.name === 'creator') return this.creator
      else {
        const permissions = user.all_permissions.map(permission => permission.codename);

        permissions.forEach(permission => {
          const obj = {
            'action': 'manage',
            'subject': permission
          }

          this.brand.push(obj);
        });

        return this.brand;
      }
    }
    return this.base
  }
}
